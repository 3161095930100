/** @jsx jsx **/
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Container, Grid, Heading, Text, jsx } from 'theme-ui'
import { CollectionTemplateType, ProductItemComponentType, ProductItemType } from '~/@types/models'
import CollectionLayout from '~/components/layout/collection-layout'
import ProductItem from '~/components/product-item'
import CollectionProducts from '~/containers/collection-products'
import { space } from '~/gatsby-plugin-theme-ui'
import SanitizeHtml from '~/components/sanitize-html'
import { ImageSize } from '~/components/product-item/types'

type Props = CollectionTemplateType

type QueryResult = {
  pageData: {
    frontmatter: {
      templateKey: string
      headerVariant: string
      title: string
      description: string
      description2: string
    }
    html: string
  }
}

const CollectionFeatured = ({ pageContext: { data } }: Props) => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "insignia" } }) {
        frontmatter {
          templateKey
          headerVariant
          title
          description
          description2
        }
        html
      }
    }
  `)

  type BlockTextProps = {
    content: string
  }

  const BlockText = ({ content }: BlockTextProps) => {
    return (
      <Box>
        <Heading
          as="h3"
          variant="title4"
          sx={{
            mb: [15, null, 32],
            ml: [null, null, null, -64],
            pl: [null, null, null, 64],
            fontSize: '32px !important',
            color: '#2b2b2b',
          }}
        >
          <SanitizeHtml content={content} />
        </Heading>
      </Box>
    )
  }

  const {
    frontmatter: { description, description2, title },
    html: content,
  } = query.pageData
  return (
    <CollectionLayout
      title={title}
      description={description}
      handle={data.handle}
      ankleContent={data.metafields?.ankleContent}
      heroHeader={{
        titleVariant: 'nigtRider',
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
      containerSx={{
        minHeight: '0 !important',
      }}
      catalog={data.catalog}
    >
      <Container
        as="section"
        sx={{
          my: [20, null, 50],
        }}
      >
        <Box as="section">
          <Box
            sx={{
              pl: [null, null, null, 64],
            }}
          >
            <BlockText content={content} />
            <Box sx={{ lineHeight: '40px' }}>
              {description && <Text sx={{ mb: 20, fontSize: '16px' }}>{description}</Text>}
              {description2 && <Text sx={{ fontSize: '16px' }}>{description2}</Text>}
            </Box>
          </Box>
        </Box>
      </Container>

      <CollectionProducts
        collectionHandle={data.handle}
        initialData={data.products as ProductItemType[]}
        pagination={data.pagination}
        data={data}
        gridVariant="layout.template-furniture"
      >
        {props => (
          <Grid variant="layout.template-default" className="product-list-featured">
            {props.products.map(item => {
              return (
                <Box key={item.handle} mb={space.l1}>
                  <ProductItem
                    product={item as ProductItemComponentType}
                    imageSize={ImageSize.NONE}
                    gridLayout="template-default"
                  />
                </Box>
              )
            })}
          </Grid>
        )}
      </CollectionProducts>
    </CollectionLayout>
  )
}

export default CollectionFeatured
